<template>
  <o-container ref="container">
    <v-card elevation="2" shaped class=" fill-height">
      <v-card-title v-if="action === ``" class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto ">
          {{ title }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
          <o-button
            :busy="busy"
            color="green"
            icon="mdi-plus"
            type="label-icon"
            label="Add New"
            @on-click="add()"
          />

          <o-button
            :busy="busy"
            color="warning"
            icon="mdi-filter"
            type="label-icon"
            label="Filter"
            @on-click="showFilter = true"
          />

          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-sync"
            type="label-icon"
            label="Refresh"
            @on-click="reset()"
          />
        </div>
      </v-card-title>
      <v-divider v-if="action === ``" />

      <!-- table -->
      <v-card-text v-if="action === ``" class="text-primary">
        <o-table
          ref="table"
          type="rest"
          :lazy="true"
          :busy="busy"
          :has-paging="true"
          :height="getHeight(442)"
          :fixed-header="true"
          method="POST"
          :rest="{
            url: `/rest/intranet/list`,
            payload: listParameters,
          }"
          :headers="headers"
          @set-busy="setBusy"
          @on-item-edit="edit"
          @on-item-delete="remove"
        >
          <template v-slot:slot="row">
            <div
              v-if="row.prop.key === `account_name`"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.account_id > 0">
                {{ row.prop.item.account_name }} ({{
                  row.prop.item.account_id
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === `advertiser_name`"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.advertiser_id > 0">
                {{ row.prop.item.advertiser_name }} ({{
                  row.prop.item.advertiser_id
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === `created_by_name`"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.created_by > 0">
                {{ row.prop.item.created_by_name }} ({{
                  row.prop.item.created_by
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === `updated_by_name`"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.updated_by > 0">
                {{ row.prop.item.updated_by_name }} ({{
                  row.prop.item.updated_by
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === `deleted_by_name`"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.deleted_by > 0">
                {{ row.prop.item.deleted_by_name }} ({{
                  row.prop.item.deleted_by
                }})
              </template>
            </div>
          </template>
        </o-table>
      </v-card-text>

      <v-card-title v-if="action !== ``" class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto">
          {{ `${action} - ${title}` }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
          <o-button
            :busy="busy"
            color="success"
            icon="mdi-content-save"
            type="label-icon"
            label="Save"
            @on-click="save()"
          />

          <o-button
            v-if="action === `edit` && model.deleted_at"
            :busy="busy"
            color="danger"
            icon="mdi-refresh"
            type="label-icon"
            label="Restore"
            @on-click="restore()"
          />

          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-keyboard-backspace"
            type="label-icon"
            label="Back"
            @on-click="action = ''"
          />
        </div>
      </v-card-title>

      <v-divider v-if="action !== ``" />
      
      <!-- editor -->
      <div v-if="action !== ``" class="ml-5 pb-5 mr-5 o-container-content">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12" class="pa-0">
                <o-combo-box
                  v-model="model.account_id"
                  :busy="busy"
                  label="Account"
                  :items="accounts"
                  @on-change="getAdvertisers"
                />
              </v-col>
              <v-col cols="12" class="pa-0">
                <o-combo-box
                  v-model="model.advertiser_id"
                  :busy="busy"
                  label="Advertiser"
                  :items="advertisers"
                />
              </v-col>
              <v-col cols="12" class="pa-0" 
              >
                <o-input
                  v-model="model.web_segment_id"
                  :busy="busy"
                  label="Web Segment ID"
                  :is-required="true"
                  :rules="[(v) => !!v || `This is required`]"
                />
              </v-col>
              <v-col cols="12" class="pa-0">
                <o-input
                  v-model="model.title"
                  :busy="busy"
                  label="Title"
                  :is-required="true"
                  :rules="[(v) => !!v || `This is required`]"
                />
              </v-col>
              <v-col cols="12" class="pa-0">
                <o-string-list
                  v-model="model.pixel_ids"
                  :busy="busy"
                  label="Pixel Id"  
                />
              </v-col>
              <v-col cols="12" class="pa-0">
                <o-input
                  v-model="model.status"
                  :busy="busy"
                  label="Status"
                  :is-required="true"
                  :rules="[(v) => !!v || `This is required`]"
                />
              </v-col>
              <v-col cols="12" class="pa-0">
                 <o-input
                  v-model="model.ttl"
                  :busy="busy"
                  type="integer"
                  label="TTL"
                  :is-required="true"
                  :rules="[(v) => !!v || `This is required`]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </div>

      <!-- filter -->
      <o-drawer
        v-if="!busy"
        :auto-close="true"
        :actions="[
          {
            key: 'on-item-reset',
            label: 'RESET',
            icon: 'mdi-refresh',
            color: 'warning',
          },
          {
            key: 'on-item-search',
            label: 'SEARCH',
            icon: 'mdi-text-box-search-outline',
            color: 'success',
          },
        ]"
        button-icon="mdi-filter-plus-outline"
        :title="`FILTERS`"
        :use-button="false"
        width="50vw"
        :visible="showFilter"
        @on-item-search="
          showFilter = false;
          reset();
        "
        @on-item-reset="
          showFilter = false;
          reset(true);
        "
        @on-close="showFilter = false"
      >
        <v-row class="pa-0 ma-0">
          <v-col cols="4" offset="4" style="display:none">
            <o-check-box v-model="strictFilter" :busy="busy" label="Strict" />
          </v-col>
          <v-col cols="12" class="pa-0">
            <o-combo-box
              v-model="parameters.account_id"
              :busy="busy"
              label="Account"
              :items="accounts"
              :multiple="true"
              @on-change="getAdvertisers"
            />
          </v-col>
          <v-col cols="12" class="pa-0">
            <o-combo-box
              v-model="parameters.advertiser_id"
              :busy="busy"
              label="Advertiser"
              :items="advertisers"
              :multiple="true"
            />
          </v-col>
          <v-col cols="12" class="pa-0">            
            <o-input
              v-model="parameters.web_segment_id"
              :busy="busy"
              label="Web Segment ID"
              :hasClear="true"
            />
          </v-col>
          <v-col cols="12" class="pa-0">
            <o-input
              v-model="parameters.title"
              :busy="busy"
              label="Title"
              :hasClear="true"
            />
          </v-col>
          <v-col cols="12" class="pa-0">
            <o-input
              v-model="parameters.pixel_id"
              :busy="busy"
              label="Pixel ID"
              :hasClear="true"
            />
          </v-col>
          <v-col cols="12" class="pa-0">
            <o-input
              v-model="parameters.status"
              :busy="busy"
              label="Status"
              :hasClear="true"
            />
          </v-col>
        </v-row>
      </o-drawer>
    </v-card>
  </o-container>
</template>

<script>
import RestApi from "@/services/RestApi";
import { mapGetters } from "vuex";

export default {
  name: `ManageWebSegment`,
  mixins: [RestApi],
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: `Web Segment`,
  },
  data() {
    return {
      title: `Web Segment`,
      busy: false,
      strictFilter: false,
      action: ``,
      valid: false,
      baseModel: {
        id: 0,
        web_segment_id: "",
        account_id: 0,
        advertiser_id: 0,
        title: "",
        pixel_id: "",
        status: "",
        ttl: 0,
        created_at: "",
        created_by: 0,
        updated_at: "",
        updated_by: 0,
        deleted_at: "",
        deleted_by: 0,
      },
      model: {},
      originalModel: {},
      parameters: {
        web_segment_id: "",
        account_id: [],
        advertiser_id: [],
        title: "",
        pixel_id: ``,
        status: "",
      },
      headers: [
        {
          text: `#`,
          align: `end`,
          value: `row_no`,
          type: `int`,
          width: 100,
        },
        {
          text: `Account Name`,
          align: `start`,
          sortable: false,
          value: `account_name`,
          type: `slot`,
        },
        {
          text: `Advertiser Name`,
          align: `start`,
          sortable: false,
          value: `advertiser_name`,
          type: `slot`,
        },
        {
          text: `Segment Id`,
          align: `start`,
          sortable: false,
          value: `web_segment_id`,
          type: `text`,
        },
        {
          text: `Title`,
          align: `start`,
          sortable: false,
          value: `title`,
          type: `text`,
        },
        {
          text: `Pixel ID`,
          align: `start`,
          value: `pixel_ids`,
          type: `string-array`,
        },
        {
          text: `Status`,
          align: `start`,
          value: `status`,
          type: `text`
        },
        {
          text: `TTL`,
          align: `end`,
          value: `ttl`,
          type: `int`,
        },
        {
          text: `Created Date`,
          align: `start`,
          value: `created_at`,
          width: 120,
          type: `date-time`,
        },
        {
          text: `Created By`,
          align: `start`,
          value: `created_by_name`,
          width: 120,
          type: `slot`,
        },
        {
          text: `Updated Date`,
          align: `start`,
          value: `updated_at`,
          width: 120,
          type: `date-time`,
        },
        {
          text: `Updated By`,
          align: `start`,
          value: `updated_by_name`,
          width: 120,
          type: `slot`,
        },
        {
          value: `action`,
          type: `action`,
          width: 100,
          actions: [
            {
              key: `on-item-edit`,
              label: `Edit`,
              icon: `mdi-pencil-box-outline`,
              color: `success`,
            },
            {
              key: `on-item-delete`,
              label: `Delete`,
              icon: `mdi-trash-can-outline`,
              color: `danger`,
            },
          ],
        },
        {
          text: `Deleted Date`,
          align: `start`,
          value: `deleted_at`,
          width: 120,
          type: `date-time`,
        },
        {
          text: `Deleted By`,
          align: `start`,
          value: `deleted_by_name`,
          width: 120,
          type: `slot`,
        },
      ],
      accounts: [],
      advertisers: [], 
    };
  },
  computed: {
    ...mapGetters([`loggedInUser`]),
  },
  watch: {},
  created() {
    this.listParameters.fields = [
      `ROW_NUMBER() OVER(ORDER BY a.deleted_at DESC, a.updated_at DESC) row_no`,
      `a.*`,
      `b.account_name`,
      `e.advertiser_name`,
      `concat(c.last_name, ', ', c.first_name) as created_by_name`,
      `concat(u.last_name, ', ', u.first_name) as updated_by_name`,
      `concat(d.last_name, ', ', d.first_name) as deleted_by_name`,
    ];
    this.listParameters.sources = {
      main: `web_segments a`,
      children: [
        {
          name: `accounts b`,
          on: `a.account_id = b.account_id`,
        },
        {
          name: `advertisers e`,
          on: `a.advertiser_id = e.advertiser_id and a.account_id = e.account_id`,
        },
        {
          name: `users c`,
          on: `a.created_by = c.id`,
        },
        {
          name: `users u`,
          on: `a.updated_by = u.id`,
        },
        {
          name: `users d`,
          on: `a.deleted_by = d.id`,
        },
      ],
    };
    this.listParameters.orders = [
      {
        sort: `a.deleted_at`,
        dir: `DESC`,
      },
      {
        sort: `a.updated_at`,
        dir: `DESC`,
      },
    ];

    for (const [k, v] of Object.entries(this.parameters)) { 
      if (this.$route.query[k]) {
        this.parameters[k] = this.$route.query[k];
      }
    }    

    this.parameters.account_id = this.stringToNumberArray(this.parameters.account_id);
    this.parameters.advertiser_id = this.stringToNumberArray(this.parameters.advertiser_id);

    const wheres = this.setParameters();
    if (wheres.length > 0)
      this.listParameters.wheres = [
        wheres.join(` ${this.strictFilter ? ` AND ` : ` OR `} `),
    ];
  },
  async mounted() {
    this.accounts = await this.getList(
      ["account_id as id, account_name as name"],
      "accounts",
      [],
      [],
      "name"
    );
  },
  methods: {
    add() {
      this.action = `add`;
      this.model = _.cloneDeep(this.baseModel);
    },
    edit(item) {
      this.model = _.cloneDeep(item);
      this.originalModel = _.cloneDeep(item);
      this.action = `edit`;
    },    
    async save() {
      if (!this.$refs.form.validate()) return false;
      const errors = [];
      const url = `/rest/intranet/query`;
      const model = _.cloneDeep(this.model);      

      if (this.action === `add`) {   
        const parameters = _.cloneDeep(this.addParameters);
        parameters.table = `web_segments`;
        parameters.fields.push({
          field: `web_segment_id`,
          value: this.model.web_segment_id,
        }); 
        parameters.fields.push({
          field: `account_id`,
          value: this.model.account_id,
        });
        parameters.fields.push({
          field: `advertiser_id`,
          value: this.model.advertiser_id,
        });
        parameters.fields.push({
          field: `title`,
          value: this.model.title,
        });
        parameters.fields.push({
          field: `status`,
          value: this.model.status,
        });
        parameters.fields.push({
          field: `pixel_ids`,
          value: JSON.stringify(this.model.pixel_ids),
        });
        parameters.fields.push({
          field: `ttl`,
          value: this.model.ttl,
        });
        parameters.fields.push({
          field: `created_by`,
          value: this.loggedInUser.id
        });
        parameters.fields.push({
          field: `created_at`,
          value: new Date().toUTCString(),
        });

        await this.postData(url, parameters)
          .then((response) => {
            this.$refs.container.snackBar(
              `Successfully added ${model.agency_name} (${model.agency_id}).`,
              `green`,
              2000
            ); 
          })
          .catch((err) => {
            errors.push(err.data.message);
          });
      }
      if (this.action === `edit`) {
        const parameters = _.cloneDeep(this.updateParameters);
        parameters.table = `web_segments`;
         parameters.fields.push({
          field: `web_segment_id`,
          value: this.model.web_segment_id,
        }); 
        parameters.fields.push({
          field: `account_id`,
          value: this.model.account_id,
        });
        parameters.fields.push({
          field: `advertiser_id`,
          value: this.model.advertiser_id,
        });
        parameters.fields.push({
          field: `title`,
          value: this.model.title,
        });
        parameters.fields.push({
          field: `pixel_ids`,
          value: JSON.stringify(this.model.pixel_ids),
        });
        parameters.fields.push({
          field: `status`,
          value: this.model.status,
        });
        parameters.fields.push({
          field: `ttl`,
          value: this.model.ttl,
        });
        parameters.fields.push({
          field: `updated_by`,
          value: this.loggedInUser.id
        });
        parameters.fields.push({
          field: `updated_at`,
          value: new Date().toUTCString(),
        });

        parameters.wheres.push({ field: `id`, value: this.model.id });
        await this.postData(url, parameters)
          .then((response) => {
            this.$refs.container.snackBar(
              `Successfully updated ${model.agency_name} (${model.agency_id}).`,
              `green`,
              2000
            ); 
          })
          .catch((err) => {
            errors.push(err.data.message);
          });
      }

      if (errors.length > 0) {
        this.$refs.container.snackBar(
          `Error updating item: ${errors.join(",")}`,
          `red`,
          2000
        );
      } else {
        this.action = ``;
      }
    },
    async remove(item) {
      this.model = _.cloneDeep(item);
      await this.$refs.container
        .confirm(
          `Confirmation`,
          `You are deleting <strong>${this.model.agency_name} (${this.model.agency_id})</strong>. Do you want to continue?`,
          `red`
        )
        .then(async (ans) => {
          if (ans) {
            const parameters = _.cloneDeep(this.updateParameters);
            const url = `/rest/intranet/query`;
            parameters.table = 'web_segments';
            parameters.fields.push({
              field: `deleted_by`,
              value: this.loggedInUser.id
            });
            parameters.fields.push({
              field: `deleted_at`,
              value: new Date().toUTCString(),
            });
            parameters.wheres.push({ field: `id`, value: this.model.id });
            await this.postData(url, parameters)
              .then((response) => {
                this.$refs.container.snackBar(
                  `The record of ${this.model.agency_name} (${this.model.agency_id}) was successfully deleted.`,
                  `red`,
                  2000
                );
                this.reset();
              })
              .catch((err) => {
                this.$refs.container.snackBar(
                  `Error removing item: ${err.data.message}`,
                  `red`,
                  2000
                );
              });
          }
        });
    },
    async restore() {
      await this.$refs.container
        .confirm(
          `Confirmation`,
          `You are restore <strong>${this.model.agency_name} (${this.model.agency_id})</strong>. Do you want to continue?`,
          `red`
        )
        .then(async (ans) => {
          if (ans) {
           const parameters = _.cloneDeep(this.updateParameters);
            const url = `/rest/intranet/query`;
            parameters.table = 'agencies';
            parameters.fields.push({
              field: `updated_by`,
              value: this.loggedInUser.id
            });
            parameters.fields.push({
              field: `updated_at`,
              value: new Date().toUTCString(),
            });
            parameters.fields.push({
              field: `deleted_by`,
              value: null
            });
            parameters.fields.push({
              field: `deleted_at`,
              value: null
            });
            parameters.wheres.push({ field: `id`, value: this.model.id });
            await this.postData(url, parameters)
              .then((response) => {
                this.$refs.container.snackBar(
                  `The record of ${this.model.agency_name} (${this.model.agency_id}) was successfully restored.`,
                  `red`,
                  2000
                );
                this.reset();
              })
              .catch((err) => {
                this.$refs.container.snackBar(
                  `Error removing item: ${err.data.message}`,
                  `red`,
                  2000
                );
              });
          }
        });
    },
    async reset(clear) {
      await this.clearQueryString();
      this.listParameters.wheres = [];
      if (clear) {
        this.parameters = {
          web_segment_id: "",
          account_id: [],
          advertiser_id: [],
          title: "",
          pixel_id: ``,
          status: "",
          ttl: 0,        
        };
      }
      const wheres = this.setParameters();
      if (wheres.length > 0)
        this.listParameters.wheres = [
          wheres.join(` ${this.strictFilter ? ` AND ` : ` OR `} `),
        ];

      await this.changeQueryString(this.parameters);

      this.action = ``;
      this.model = _.cloneDeep(this.baseModel);
      setTimeout(async () => {
        this.$refs.table.refresh();
      }, 200);
    }, 
    setParameters() {
      const wheres = [];
       
      if (this.parameters.account_id.length > 0) { 
        this.parameters.account_id = this.stringToStringArray(this.parameters.account_id)
        wheres.push(
          `a.account_id in (${this.parameters.account_id.join(
            `, `
          )})`
        );
      }
      if (this.parameters.advertiser_id.length > 0) {
        wheres.push(
          `a.advertiser_id in (${this.stringToStringArray(this.parameters.advertiser_id).join(
            `, `
          )})`
        );
      } 
      if (this.parameters.web_segment_id != ``) {
        wheres.push(
          this.getWhereString(`a.web_segment_id`, this.parameters.web_segment_id)
        );
      }
      if (this.parameters.title != ``) {
        wheres.push(this.getWhereString(`a.title`, this.parameters.title));
      }
      if (this.parameters.pixel_id != ``) {
        wheres.push(this.getWhereString(`a.pixel_id`, this.parameters.pixel_id));
      }
      if (this.parameters.status != ``) {
        wheres.push(
          this.getWhereString(`a.status`, this.parameters.status)
        );
      }
      
      return wheres;
    }
  },
};
</script>
<style lang="scss" scoped>
.o-container-content {
  min-height: calc(100vh - 278px);
  max-height: calc(100vh - 278px);
  overflow-y: scroll;
}
</style>
